import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Cookies} from '../../helpers/Cookies';
import {Observable, of} from 'rxjs';
import {LiveService} from '../live/live.service';
import {HttpClient} from '@angular/common/http';
import storage from 'store';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(
      private api: ApiService,
      private http: HttpClient,
      public live: LiveService
    ) {}

    public login(data): Observable<any> {
        Cookies.set('token', '', -1);
        return this.api.post('/login', data);
    }

    public login_by_token(data): Observable<any> {
      Cookies.set('token', '', -1);
      return this.api.post('/login_by_token', data);
    }

    public validate_2fa(data): Observable<any> {
      Cookies.set('token', '', -1);
      return this.api.post('/login/validate-2fa', data);
    }

    public changePassword(userId, body) {
      return this.api.put(`/passwords/change/${userId}`, {
        body
      });
    }

    public logout(): Observable<any> {
        return this.api.post('/logout', {});
    }

    public modelInfo(): Observable<any> {
      return this.api.get('/login/model-info', {})
    }

    public groupFieldsInfo(): Observable<any> {
      return this.api.get('/login/group-fields-info', {});
    }

    public requestRestorePassword(body): Observable<any> {
      return this.api.post('/login/restore-password', {
        body
      });
    }

    public isAuthenticated(): boolean {
      return Cookies.get('token') && storage.get('user');
    }

    public incoming(): Observable<any> {
        return this.live.subscribe('user');
    }

    public kpi(): Observable<any> {
        return this.live.subscribe('kpi');
    }

    public timer(): Observable<any> {
        return this.live.subscribe('timer');
    }

    public getUsers(): Observable<any> {
        return this.api.get('/users', {});
    }

    public ip(): Observable<any> {
        try {
            const ipDetails = JSON.parse(sessionStorage.getItem('ip_details') || 'null');

            if(ipDetails) {
                return of(ipDetails)
            }
        } catch(e){}

        const ip = Cookies.get('cve_ip') || '93.175.202.243';

        return this.http.get('https://ip-api.canadianvisa.org/ip-info?ip=' + ip, {
          headers: {
            Authorization: 'Bearer ' + environment.ipInfoBearer
          }
        }).pipe(
            tap((r) => {
                try {
                    sessionStorage.setItem('ip_details', JSON.stringify(r))
                } catch(e) {}
            })
        );
    }

    public device(): Observable<any> {
        return this.live.subscribe('device');
    }

    public notifications(): Observable<any> {
        return this.live.subscribe('notifications');
    }

    public call(): Observable<any> {
        return this.live.subscribe('user_call');
    }

    public blocked(): Observable<any> {
        return this.live.subscribe('user_call');
    }

    public unblock(id): Observable<any> {
      return  this.api.post(`/users/${id}`, {});
    }

    public agent_login(data): Observable<any> {
      return this.api.post('/agent_login', data);
    }

    public getUsersForLogin(param): Observable<any> {
      return this.api.get('/agent_login', {
        params: param
      });
    }

    public getManagerLoginData(managerId, userId): Observable<any> {
      return this.api.get('/agent_login/login-back/' + managerId + '/' + userId, {});
    }

    public sendReferralLink(id, type, template): Observable<any> {
    return this.api.post('/users/' + type + '/' + id + '/' + 'send-referral', {
      body: template
    });
  }

    public getAvatar(crm_id): string {
        if (crm_id === undefined) {
            return '';
        }

        const baseUrl = 'https://d1ac9zce9817ms.cloudfront.net/images/icons';

        const number = parseInt(String(String(crm_id).split('').map(s => s.charCodeAt(0)).reduce((a, b) => a + b, 0) % 15), 10);

        return baseUrl + String(number) + '.svg';

    }

    public requestQrCode(userId): Observable<any> {
      return this.api.post('/login/' + userId + '/request-qr-code', {});
    }


    // PIP / PR
  public getAgents(params): Observable<any> {
    return this.api.get('/pip_pr/agents', {params});
  }

  public getUser(hash: string): Observable<any> {
    return this.api.get(`/pip_pr/agents/${hash}`, {});
  }

  public getDocuments(hash: string, params): Observable<any> {
    return this.api.get(`/pip_pr/folders/${hash}`, {params});
  }

  public getTemplates(): Observable<any> {
    return this.api.get(`/pip_pr/all-templates`, {});
  }

  public deleteFile(folder, file): Observable<any> {
    return this.api.delete(`/pip_pr/pip-pdf/${folder}/${file}`, {});
  }

  public moveFile(folder, file, body): Observable<any> {
    return this.api.post(`/pip_pr/pip-pdf/move/${folder}/${file}`, {body});
  }

  public editFile(file, body): Observable<any> {
    return this.api.put(`/pip_pr/pip-pdf/${file}`, {body});
  }

  public generatePdf(body): Observable<any> {
    return this.api.post('/pip_pr/pip-pdf', {body});
  }

  public savePdf(save, preview, body): Observable<any> {
    return this.api.post(`/pip_pr/pip-pdf/save/${save}/${preview}`, {body});
  }

  // Declaration of Transaction doc
  public generatePdfDot(body: {html?: string, payment_id: string}): Observable<any> {
    return this.api.post('/document/generate-dot/v2' , {body});
  }

  public saveDotDoc(lead_number, status, body: FormData): Observable<any> {
    return this.api.post(`/document/${lead_number}/declaration_of_transaction/${status}/v2`, {body});
  }

  // Folders
  public deleteFolder(folder: number): Observable<any> {
    return this.api.delete(`/pip_pr/folders/${folder}`, {});
  }

  public editFolder(folder: number, body: any): Observable<any> {
    return this.api.put(`/pip_pr/folders/${folder}`, {body});
  }

  public getFoldersList(hash: string): Observable<any> {
    return this.api.get(`/pip_pr/all-folders/${hash}`, {});
  }

  public createFolder(hash: string, body: any): Observable<any> {
    return this.api.post(`/pip_pr/folders/${hash}`, {body});
  }


  public addComment(document_id: number, body): Observable<any> {
    return this.api.post(`/comments/documents/${document_id}`, {body});
  }

  public getComment(document_id: number, params): Observable<any> {
    return this.api.get(`/comments/documents/${document_id}/documents`, {params});
  }


}
