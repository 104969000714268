import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import SearchResponse from '../../response/search.response';
import {Observable} from 'rxjs/Rx';

@Injectable({
    providedIn: 'root'
})
export class OpportunitiesService {

    constructor(private api: ApiService) {
    }

    public getList(params): Observable<any> {
        if (params instanceof SearchResponse) {
            params = params.getReqData();
        }
        return this.api.get('/opportunities', {
            params: params
        });
    }

    public getAllOpportunities(params): Observable<any> {
        if (params instanceof SearchResponse) {
            params = params.getReqData();
        }
        return this.api.get('/opportunities/tech', {
            params: params
        });
    }

    public getOpportunitiesByLeadNumber(leadNumber): Observable<any> {
        return this.api.get('/opportunities/lead/' + leadNumber, {});
    }

    // Takes from payment history on BE
    public getOpportunitiesByLeadNumberV2(leadNumber): Observable<any> {
        return this.api.get('/leads/payments/' + leadNumber, {});
    }

    public createPartial(id, data): Observable<any> {
        return this.api.post('/opportunities/' + id + '/partial', {
            body: data
        });
    }
    public deleteOpportunities(ids): Observable<any> {
        return this.api.post('/opportunities/delete', {
            body: {
                opportunity_ids: ids
            }
        });
    }

    public downloadOpportunity(params, ids = [], type = {result: 0, password: 0}): Observable<any> {
        if (params instanceof SearchResponse) {
            params = params.getReqData();
        }
        params = Object.assign(params, {password: type.password});
        if (ids.length > 0 && type.result === -1) {
            return this.api.get('/opportunities?excel=all&excel_users=' + ids, {
                params: params,
                responseType: 'text'
            });
        } else if (type.result === 1) {

            return this.api.get('/opportunities?excel=all', {
                params: params,
                responseType: 'text'
            });
        } else if (type.result === 2) {
            return this.api.get('/opportunities?excel=' + params.page_size, {
                params: params,
                responseType: 'text'
            });
        }
    }

    public getDetails(params): Observable<any> {
      return this.api.get('/opportunities/get-details', { params });
    }
}
