export const environment = {
  appVersion: require('../../package.json').version,
  production: true,
  name: 'stage',
  entityInfoUrl: 'https://entity-api.acggroupcom.com/',
  publicApi: 'https://mario-dev.canadianvisa.org',
  apiURL: 'https://mario-dev.canadianvisa.org/agent',
  liveUrl: 'https://mario-events-dev.canadianvisa.org',
  // callUrl: 'https://canadian-visa-media.s3.amazonaws.com/agent-dash/call-stage',
  callUrl: 'https://cv-media-files.s3.us-west-2.amazonaws.com/js/call-system/stage_agent',
  secretKey: '039e3cea804ae6c10a53ab3a4b7ae4c9',
  notificationURL: 'https://mario-dev-notification.canadianvisa.org',
  centURL: 'wss://mario-cent-dev.canadianvisa.org/connection/websocket?format=protobuf',
  cvCanadaUrl: 'https://cvonline.canadianvisa.org',
  cvBCanUrl: 'https://cvonline.bcanadian.com',
  canadianVisaURL: 'https://retention-common-api-stage.canadianvisa.org',

  wizardV2: 'https://wizard-module-v2-stage.canadianvisa.org',
  wizard1: 'https://wizard-module.canadianvisa.org',
  wizard: 'https://wizard-module.canadianvisa.org',
  commissions: 'https://commission.canadianvisa.org',
  immiUrl: 'https://evaluation-new.pioneersa.org',
  cvcdUrl: 'https://evaluation-client.pioneersa.org/',
  productsURL : 'https://product-module.canadianvisa.org',
  ipInfoBearer: 'lNa5n9OMGgmDWSgLuml3rx7O1iVO5t3CpRcrPgjVD6ccqHnYio'

};
